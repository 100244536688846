import {useEffect, useLayoutEffect, useRef, useState} from "react";
import _ from "lodash";
import {Collapse} from 'bootstrap'
import { NavLink, useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";


export function Header(props) {
    const [shrinkClass, setShrinkClass] = useState("");
    const [isHome, setIsHome] = useState(true);

    const navBarInstanceRef = useRef(null);
    const navBarRef = useRef(null);
    useEffect(() => {
        const _handleShrinkNav = _.debounce(function () {
            let scrollTop = window.scrollY;
            if (scrollTop > 150) {
                setShrinkClass('header-shrink');
            } else {
                setShrinkClass('');
            }

        }, 500);

        window.addEventListener("scroll", _handleShrinkNav);
        return () => window.removeEventListener("scroll", _handleShrinkNav);

    }, []);

    let location = useLocation();
    useEffect(() => {
        //only in home page
        (location.pathname === '/') ? setIsHome(true) : setIsHome(false);

    }, [location?.pathname]);

    // let mintActive = useMatch({path: "/mint", end: true}) ? 'active' : '';
    useLayoutEffect(() => {
        navBarInstanceRef.current = new Collapse(navBarRef.current, {toggle: false});
        let navbarLink = document.querySelectorAll('.nav-link');
        for (let i = 0; i < navbarLink.length; i++) {
            navbarLink[i].addEventListener("click", function () {
                _hideMenu();
            });
        }


    }, []);

    function _hideMenu() {
        if (navBarInstanceRef?.current) {
            navBarInstanceRef.current?.hide();
        }
    }



    const linkClasses = "nav-link menu-link";
    const linkClassesActive = "nav-link menu-link active";

    return <header className={`header fixed-top ${shrinkClass}`} id="header">
        <div id="nav-menu-wrap">
            <div className="container">
                <nav className="navbar navbar-expand-lg p-0">
                    <span id="align_helper"/>

                    <a className="navbar-brand" href="https://westsidewalruses.com">
                        <img src="images/logos/Westside_Walruses_Logo.png" alt="Logo White"
                             className="img-fluid logo-transparent"/>
                    </a>
                    <button id="navbarButton" className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#fixedNavbar" aria-controls="fixedNavbar" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="togler-icon-inner">
                                <span className="line-1"/>
                                <span className="line-2"/>
                                <span className="line-3"/>
                            </span>
                    </button>
                    <div ref={navBarRef} className="collapse navbar-collapse main-menu justify-content-start"
                         id="fixedNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link nav-page " to="mint">Mint</NavLink>
                            </li>


                            <li className="nav-item">
                                <HashLink className={isHome &&  props.activeSection === 1 ?  linkClassesActive : linkClasses} data-to-scrollspy-id="section_perks" to= "/#scr_perks">Perks</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink  className={ isHome &&  props.activeSection === 2 ?  linkClassesActive : linkClasses} data-to-scrollspy-id="section_tusk" to= "/#scr_tusk">LORE</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink  className={ isHome &&  props.activeSection === 3 ?  linkClassesActive : linkClasses} data-to-scrollspy-id="section_rarity"  to= "/#src_rarity">Rarity</HashLink>
                            </li>

                            <li className="nav-item">
                                <HashLink  className={ isHome &&  props.activeSection === 4 ?  linkClassesActive : linkClasses} data-to-scrollspy-id="section_roadmap" to= "/#src_roadmap">Roadmap</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink  className={ isHome &&  props.activeSection === 5 ?  linkClassesActive : linkClasses} data-to-scrollspy-id="section_team_section"  to= "/#src_team_section">Team</HashLink>
                            </li>

                            {/*<li className="nav-item navbar-btn-resp d-flex align-items-center">*/}
                            {/*    <a href="#" className="primary-button btnPurple me-3"> My Walruses</a>*/}
                            {/*</li>*/}
                            <li className="nav-item nav-socials d-flex align-self-center">
                                <a target="_blank" className="white-link ms-3 circle-btn" title="Twitter"
                                   href="https://twitter.com/RealWalrusNFT" rel="noreferrer">
                                    <i className="icon-twitter"/>
                                </a>

                                <a target="_blank" className="white-link ms-3 circle-btn" title="Discord"
                                   href="https://discord.gg/WestsideWalruses" rel="noreferrer">
                                    <i className="icon-discord"/>
                                </a>
                                <a target="_blank" className="white-link ms-3 circle-btn" title="OpenSea"
                                   href="https://opensea.io/collection/westsidewalruses" rel="noreferrer">
                                    <i className="icon-open-sea"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>;
}
