import { useLayoutEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";


export function Bg() {
    const [inlineStyle,setInlineStyle]= useState({});
    const myRef = useRef();
    useLayoutEffect (() => {
        let innerHeight = window.innerHeight;

        if (myRef.current?.clientHeight > innerHeight) {
            setInlineStyle({height:innerHeight + "px"})
        }
    }, []);
    return <section style={inlineStyle} ref={myRef} id="scr_main_bg" className="bg d-flex">
        <div id="mint_box" className="round_cr d-flex flex-column">
            {/*<a href="#" title="Mint" id="mint_button" className="btnPurple">*/}
            {/*    Mint*/}
            {/*</a>*/}
            <NavLink className="btnPurple "  id="mint_button" to="mint">Mint</NavLink>
            <div id="mint_box_count">
                First 250 of 10,000
            </div>
        </div>
        <div className="mouse_scroll">
            <div className="mouse">
                <div className="wheel"/>
            </div>
            <div>
                <span className="m_scroll_arrows unu"/>
                <span className="m_scroll_arrows doi"/>
                <span className="m_scroll_arrows trei"/>
            </div>
        </div>
    </section>;
}
