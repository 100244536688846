import './App.css';
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {Home} from "./components/Home";
import {Route, Routes} from "react-router-dom";
import {Mint} from "./components/pages/Mint";
import useScrollSpy from 'react-use-scrollspy';
import {useRef} from "react";

function App() {
    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -80,
    });

    return (
        <>
            <Header activeSection={activeSection}/>
            <Routes>
                <Route path="/" element={<Home sectionRefs={sectionRefs} />} />
                <Route path="/mint" element={<Mint />} />
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
