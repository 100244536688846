// import {useTimer} from 'react-timer-hook';
import styles from "./mint.module.css";
import {useEffect, useRef, useState} from "react";
import _ from "lodash";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import NftABI from "../../ABIs/WalrusNft.json";


import {CSSTransition} from "react-transition-group";
// import {SimpleModal} from "../Modals/SimpleModal";
import Web3 from "web3";


let web3Modal = null;
let web3 = null;
let NftContract;

let NftPrice = 0.11; // ether


// function CountDown() {
//     const expiryTimestamp = new Date(1651910926 * 1000);
//     const {
//         seconds,
//         minutes,
//         hours,
//         days,
//         isRunning,
//         start,
//         pause,
//         resume,
//         restart,
//     } = useTimer({
//         expiryTimestamp, onExpire: () => {
//             console.warn('Timer onExpire called');i
//             // setMintStarted(true) //todo redux
//         }
//     });
//     return <div className={styles.counter_digit}>
//         {days}d : {hours}h : {minutes}m : {seconds}s
//     </div>;
// }


export function Mint() {

    // @toto disable btn: Mint started and have metamask

    // const [metamaskState, setMetamaskState] = useState(MetaMaskState.notInstalled);
    const [mintStarted, setMintStarted] = useState(true);
    const [mintLoading, setMintLoading] = useState(false);
    const [mintSuccess, setMintSuccess] = useState(false);
    const [txHash, setTxHash] = useState('');
    const [tokenIDs, setTokenIDs] = useState('');
    const [totalSupply, setTotalSupply] = useState('-');
    // const [noWallet, setNoWallet] = useState(true);
    const [walletConnected, setWalletConnected] = useState(false);
    const [mintCount, setMintCount] = useState(1);
    const [walletAccount, setWalletAccount] = useState('');
    const nodeRef = useRef(null);
    const successRef = useRef(null);


    const NftAddress = "0x64b83712f5573488767a40c6216d0e6f726a64bf";

    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: "8409a99b92124704a1f430556e6f06b2" //todo
            }
        },

    };



    useEffect(() => {
        window.scrollTo(0, 20);
        web3Modal = new Web3Modal({
            network: "mainnet",
            theme: "dark",
            cacheProvider: true,
            providerOptions
        });
        web3Modal.on("accountsChanged", (accounts) => {
            console.log(accounts);
        });


        web3Modal.on("chainChanged", (chainId) => {
            console.log(chainId);
        });


        web3Modal.on("connect", (info) => {
            const {accounts} = info;

            let selectedAddress = null;
            if (accounts && accounts.length > 0) {
                selectedAddress = accounts[0];
                setWalletAccount(selectedAddress)
                setWalletConnected(true);
            } else if (info.isMetaMask) { // check metamask
                selectedAddress = info.selectedAddress;
                setWalletAccount(selectedAddress)
                setWalletConnected(true);
            } else {
                return;
            }

        });

        web3Modal.on("disconnect", (error) => {
            console.log(error);
        });

        _connectToWallet();


    }, []);

    // How many user wants
    function changeMintCount(num) {
        const newVar = mintCount + num;
        if (newVar > 0 && newVar < 11) {
            setMintCount(newVar)
        }

    }
    async function _initContracts() {
        // const provider = await web3Modal.connect();
        // web3 = new Web3(provider);
        try{
            NftContract = new web3.eth.Contract(NftABI.abi, NftAddress);
            //Update total supply on the page
            const nftTotalSupply = await NftContract.methods.totalSupply().call();
            setTotalSupply(nftTotalSupply)



        } catch (e) {
            console.log(e)
        }

    }

    async function _connectToWallet() {
        try {
            const provider = await web3Modal.connect();
            web3 = new Web3(provider);
            try {
                await _initContracts();

            } catch (e) {
                console.log(e)
            }

            // const accounts = await web3.eth.getAccounts();
            // setWalletAccount(accounts[0])
            // setWalletConnected(true);


        } catch (e) {
            console.log(e)
        }

    }

    async function _mintNFT() {
        try {
            //set loading
            setMintLoading(true)
            const value = Web3.utils.toWei((mintCount * NftPrice).toString() , 'ether'); // 1 * 0.003 //todo get price dynamically
            if(!NftContract){
              await  _initContracts();
            }

            const {status,transactionHash,events} = await NftContract.methods.mint(mintCount).send({
                from: walletAccount,
                gas: 200000,
                value
            });

            if(status){
                setMintLoading(false)
                setMintSuccess(true)
                setTxHash(transactionHash)
                const transferEvent = events['Transfer'];
                if(Array.isArray(transferEvent)){
                    const tokenIDs = transferEvent.map(item=>{
                        return item.returnValues.tokenId
                    })
                    setTokenIDs(tokenIDs.join(', '))
                }else {
                    setTokenIDs( transferEvent.returnValues.tokenId)
                }


                const nftTotalSupply = await NftContract.methods.totalSupply().call();
                setTotalSupply(nftTotalSupply)
            }





        } catch (e) {
            console.log(e)
        }

    }


    ///
    const connectBtn = <a onClick={_connectToWallet} className={`${styles.connectWalletBtn}  `}>
        Connect Wallet to Mint
    </a>;
    const totalSupplyInfo =  <h5 className={styles.h5_2}> {totalSupply} / 250 of 10,000</h5>
    const mintBtn = <a onClick={_mintNFT}
                       className={`${styles.connectWalletBtn} ${!mintStarted ? styles.disableBtn : null} `}>
        Mint
    </a>;
    return <div className={`${styles.inner_page} container inner-page `}>
        <div
            className={`${styles.walletStatus} ${styles.walletStatusMobile} ${walletAccount ? styles.active : null} d-md-none `}>
            {walletAccount === '' ? "Wallet Disconnected" : _.truncate(walletAccount, {
                'length': 13,
                'separator': ' '
            })}
        </div>
        <h2 className={`${styles.info_header} info_header`}>
            MINT
            <span className={`${styles.walletStatus} ${walletAccount ? styles.active : null} d-none d-md-flex `}>
                {walletAccount === '' ? "Wallet Disconnected" : _.truncate(walletAccount, {
                    'length': 13,
                    'separator': ' '
                })}
            </span>
        </h2>
        <section className={styles.container}>
            <h3 className={styles.h3}>
                MINT THE FIRST 250 WALRUSES
            </h3>


            <h5 className={styles.h5_2}>Price: <span className="cl_purple">{NftPrice} ETH  </span> / Max: 10 per Wallet</h5>
            {/*<div className={styles.counter_wrapper}>*/}

            {/*    <CountDown/>*/}
            {/*</div>*/}
            <CSSTransition nodeRef={nodeRef} in={walletConnected && !mintSuccess}  timeout={200}
                           unmountOnExit


                           classNames={{
                               appear: 'my-appear',
                               appearActive: 'my-active-appear',
                               appearDone: 'my-done-appear',
                               enter: styles.transition1Enter,
                               enterActive: styles.transition1EnterActive,
                               enterDone: 'my-done-enter',
                               exit: styles.transition1Exit,
                               exitActive: styles.transition1ExitActive,
                               exitDone: 'my-done-exit',
                           }}>
                <div ref={nodeRef}>
                    <h5 className={styles.h5_3}>Amount to Mint:</h5>
                    <div className={styles.mintCountWrapper}>
                        <div className={styles.mintCountBtn} onClick={() => changeMintCount(-1)}>
                            <img alt="Decrease amount" src="images/icons/minus.svg"/>
                        </div>

                        <div>{mintCount}</div>

                        <div className={styles.mintCountBtn} onClick={() => changeMintCount(1)}>
                            <img alt="Decrease amount" src="images/icons/plus.svg"/>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition nodeRef={successRef} in={mintSuccess} timeout={200}
                           unmountOnExit


                           classNames={{
                               appear: 'my-appear',
                               appearActive: 'my-active-appear',
                               appearDone: 'my-done-appear',
                               enter: styles.transition1Enter,
                               enterActive: styles.transition1EnterActive,
                               enterDone: 'my-done-enter',
                               exit: styles.transition1Exit,
                               exitActive: styles.transition1ExitActive,
                               exitDone: 'my-done-exit',
                           }}>
                <div ref={successRef}>
                    <h5 className={styles.h5_4}>  MINT SUCCESS</h5>

                    <p className={styles.h5}>
                        Your NFT token ids are: <b>{tokenIDs}</b>
                    </p>

                    <a className={styles.viewBtn} href={"https://goerli.etherscan.io/tx/" + txHash} target="_blank" rel="noreferrer" title="View Transaction">
                        View Transaction
                    </a>


                </div>
            </CSSTransition>




            {walletConnected ? ( mintLoading || mintSuccess ? null : mintBtn ): connectBtn}

            {walletConnected && mintLoading  ?  <div className="load-p" ><div className="load-donut"></div></div>  : null}
            {walletConnected ? totalSupplyInfo : null}

            <a className={styles.ether_icon} href="https://etherscan.io/address/0x64b83712f5573488767a40c6216d0e6f726a64bf" target="_blank"  rel="noreferrer" title="Etherscan"><img alt="Decrease amount" src="images/logos/ether.png"/></a>

        </section>
        {/*{*/}
        {/*    noWallet ?*/}
        {/*        <SimpleModal>*/}
        {/*        </SimpleModal>*/}
        {/*        : null*/}
        {/*}*/}

    </div>
}
