import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@splidejs/splide/dist/css/splide.min.css';
import './css/RezaMirjah.css';
import './css/style.css';
import './index.css';
import App from './App';
import 'lodash';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
    <App />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
