import {Bg} from "./Bg";
import {useLayoutEffect} from "react";


import Splide from "@splidejs/splide";
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
function sliderFactory(rootID, config, scroll = false) {
    return scroll ?
        new Splide(rootID, config).mount({AutoScroll}) :
        new Splide(rootID, config).mount();
}
export function Home(props) {
    useLayoutEffect(() => {

        // new ScrollSpy(document.body, {
        //     target: '#fixedNavbar',
        //     offset: 70
        // })


        const sliders = [];
        sliders.push(sliderFactory('#nft_slider', {
            perPage: 4,
            perMove: 1,
            pagination: false,
            slideFocus: false,
            keyboard: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            autoScroll: {
                speed: 2.5,
                pauseOnHover: false,
                pauseOnFocus: false
            },
            arrows: false,
            drag: false,
            padding: {left: '1rem', right: '0'},
            fixedWidth: '15rem',
            // autoWidth: true,
            type: 'loop',
            gap: '1.5rem',
            breakpoints: {
                1000: {
                    perPage: 2,
                },
                768: {
                    fixedWidth: '10.875rem',
                    perPage: 1,
                },
            }
        }, true));

        sliders.push(sliderFactory('#rarity_mobile_slider', {
            perPage: 3,
            perMove: 1,
            pagination: true,
            autoplay: true,
            interval: 2000,
            speed: 850,
            arrows: false,
            drag: false,
            slideFocus: false,
            keyboard: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            waitForTransition: false,
            fixedWidth: '30.75rem',
            gap: '1.2rem',
            type: 'loop',
            breakpoints: {
                1000: {
                    perPage: 2,
                },
                768: {
                    fixedWidth: '100%',
                    perPage: 1,

                },
            }
        }));

        sliders.push(sliderFactory('#team_mobile_slider', {
            perPage: 3,
            perMove: 1,
            pagination: true,
            autoplay: true,
            interval: 2000,
            speed: 850,
            arrows: false,
            drag: false,
            slideFocus: false,
            keyboard: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            waitForTransition: false,
            fixedWidth: '30.75rem',
            gap: '1.2rem',
            type: 'loop',
            breakpoints: {
                1000: {
                    perPage: 2,
                },
                768: {
                    fixedWidth: '100%',
                    perPage: 1,

                }
            }
        }));

        return () => {
            sliders.forEach(slider => {
              slider.destroy();
            });

        }
    }, []);

    return <>
        <Bg/>
        <section ref={props.sectionRefs[0]} id="welcome" className="text-center">
            <div className="container">
                <div id="logo_glow">
                    <div/>
                    <img className="img-fluid" alt="Purple Walrus" src="images/main/tusk_logo.png"/>
                </div>
                <p>
                    The
                    <span className="cl_purple"> Westside Walruses </span>
                    are inducting new homies into the crew. Join up
                    <span className="cl_purple"> for access </span>
                    to all of their potential utility.
                </p>
                <span className="small_line"/>
                <div id="wl_date" className="info_header">
                    MINTING NOW
                </div>
                <div id="wl_drop">
                    On The Ethereum Blockchain
                </div>
            </div>
        </section>

            <section ref={props.sectionRefs[1]} id="section_perks" className="container">
                <div id="scr_perks"/>
                <h2 className="info_header">
                    LET THE GAMES BEGIN
                </h2>

                <section id="whitelist_box" className="round_cr info_box">
                    <section id="nft_slider">
                        <div className="splide__track">
                            <div className="splide__list">
                                <div className="splide__slide">
                                    <img src="images/slider/1.jpg" alt="1"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/2.jpg" alt="2"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/3.jpg" alt="3"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/4.jpg" alt="4"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/5.jpg" alt="5"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/6.jpg" alt="6"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/7.jpg" alt="7"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/8.jpg" alt="8"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/9.jpg" alt="9"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/10.jpg" alt="10"/>
                                </div>

                                <div className="splide__slide">
                                    <img src="images/slider/11.jpg" alt="11"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div id="perk_line_wrapper">
                        <div className="perk_line">
                            <img src="images/icons/circle_check.png" alt="check "/>
                            <div>
                                ACCESS TO OUR
                                <span className="cl_purple"> PRIVATE COMMUNITY </span>
                                AND
                                <span className="cl_purple"> IRL EVENTS </span>
                                held in
                                <span className="cl_purple"> nyc</span>
                            </div>
                        </div>

                        <div className="perk_line">
                            <img src="images/icons/circle_check.png" alt="check "/>
                            <div>
                                ENTRY INTO THE
                                <span className="cl_purple"> BEER PONG TOURNEY </span>
                                OF THE CENTURY (DATES TBA)
                            </div>
                        </div>
                        <div className="perk_line">
                            <img src="images/icons/circle_check.png" alt="check "/>

                            <div>

                                <span className="cl_purple"> must Hold </span>
                                2 walruses
                                <span className="cl_purple"> to play </span>
                            </div>
                        </div>
                        <div className="perk_line">
                            <img src="images/icons/circle_check.png" alt="check "/>
                            <div>
                                tourney champs
                                <span className="cl_purple"> win 5 walruses </span>

                            </div>
                        </div>
                        <div className="perk_line">
                            <img src="images/icons/circle_check.png" alt="check "/>
                            {/*1% of   conserve global*/}
                            <div>
                                <span className="cl_purple">1% OF Primary sales</span> Will be donated to <a
                                className="cl_purple" href="https://conserveglobal.earth/" target="_blank">CONSERVE
                                GLOBAL</a>
                            </div>
                        </div>

                    </div>
                </section>

            </section>
            <section  ref={props.sectionRefs[2]} id="section_tusk" className="container">
                <div id="scr_tusk"/>
                <h2 className="info_header">
                    WESTSIDE LORE
            </h2>

                <section id="tusk_box" className=" ">
                    {/*             Only on Desktop  */}
                    <div className="info_box round_cr d-none d-md-flex cl_purple_border">
                        <div id="tusk_logo_wrapper_desktop" className="d-flex">
                            <img id="tusk_logo_desktop" className="img-fluid" alt="Purple Walrus"
                                 src="images/main/tusk_logo.png"/>

                            <p id="tusk_box_desc_desktop">
                                Get In On a Little Walrus History
                            </p>
                        </div>
                    </div>

                    {/*             Only on mobile */}
                    <div className="info_box round_cr d-md-none">

                        <p id="tusk_box_desc_mobile">
                            Get In On a Little Walrus History

                        </p>
                    </div>

                    <div id="lore" className="info_box mt-3  round_cr">
                       <p className="mb-3">
                           Back in the OG days of web3, life was fresh on the iceberg. Floor prices were mooning, gas was cheap af, and there was hella fish for errbody. But eth mining companies took over, and global warming started meltin’ all the mf glaciers. With no place to chill, the walruses decided it was time to head to Cali.
                         </p>
                        <p className="mt-4">

                           They set sail on their iceberg and after a long ass journey, they pulled up to the beaches of The Westside. The locals were all smokin’ kush so the walruses started gettin’ lit with all the new homies.
                    </p><p className="mt-4">
                           Before long, the walruses became Cali locals themselves and the rest is mf history.
                       </p>
                    </div>
                </section></section>
            <section  ref={props.sectionRefs[3]}  id="section_rarity" className="container">
                <div id="src_rarity"/>
                <h2 className="info_header">
                    RARITY
                </h2>

                <section id="rarity_box">
                    <div className="info_box round_cr flex-column">
                        {/*             Only on mobile */}
                        <div id="rarity_mobile_slider" className="d-md-none">
                            <div className="splide__track">
                                <div className="splide__list">
                                    <div className="rarity_item splide__slide">
                                        <img alt="Rarity Images" src="images/rarities/a.jpg"/>
                                        <div className="neon_gray">
                                            COMMON
                                        </div>
                                    </div>

                                    <div className="rarity_item splide__slide">
                                        <img alt="Rarity Images" src="images/rarities/c.jpg"/>
                                        <div className="neon_blue">
                                            UNCOMMON
                                        </div>
                                    </div>

                                    <div className="rarity_item splide__slide">
                                        <img alt="Rarity Images" src="images/rarities/d.jpg"/>
                                        <div className="neon_pink">
                                            RARE
                                        </div>
                                    </div>

                                    <div className="rarity_item splide__slide">
                                        <img alt="Rarity Images" src="images/rarities/e.jpg"/>
                                        <div className="neon_orange">
                                            EPIC
                                        </div>
                                    </div>

                                    <div className="rarity_item splide__slide">
                                        <img alt="Rarity Images" src="images/rarities/f.jpg"/>
                                        <div className="neon_yellow">
                                            LEGENDARY
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*             Only on Desktop  */}
                        <div id="rarity_image_bar" className="d-none d-md-flex flex-wrap justify-content-center">
                            <div className="rarity_item">
                                <img alt="Rarity Images" src="images/rarities/a.jpg"/>
                                <div className="neon_gray">
                                    COMMON
                                </div>
                            </div>

                            <div className="rarity_item">
                                <img alt="Rarity Images" src="images/rarities/c.jpg"/>
                                <div className="neon_blue">
                                    UNCOMMON
                                </div>
                            </div>

                            <div className="rarity_item">
                                <img alt="Rarity Images" src="images/rarities/d.jpg"/>
                                <div className="neon_pink">
                                    RARE
                                </div>
                            </div>

                            <div className="rarity_item">
                                <img alt="Rarity Images" src="images/rarities/e.jpg"/>
                                <div className="neon_orange">
                                    EPIC
                                </div>
                            </div>

                            <div className="rarity_item">
                                <img alt="Rarity Images" src="images/rarities/f.jpg"/>
                                <div className="neon_yellow">
                                    LEGENDARY
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"/>
                        <p className="rarity_check_des text-center ">
                            Each Westside Walrus is 100% unique and randomly generated from 200 plus traits including
                            fur, clothes,
                            tusks, snouts, expressions, earrings, eyes, headwear, and eyewear.
                        </p>
                    </div>
                </section>
            </section>
            <section  ref={props.sectionRefs[4]}  id="section_roadmap" className="container">
                <div id="src_roadmap"/>
                <h2 className="info_header">
                    ROADMAP
                </h2>

                <section id="roadmap_box" className="info_box round_cr">
                    <div className="roadmap">
                        <header>
                            <img className="roadmap_logo" alt="Roadmap Icon" src="images/icons/walrus_active.png"/>
                            <h3 className="cl_purple">THE PATH TO THE WESTSIDE</h3>
                        </header>
                        <div id="roadmap_box_table">
                            <div className="roadmap_row">
                                <div className="arrow_col">
                                    <img className="img-fluid arrow_line_icon" src="images/icons/walrus_active.png"
                                         alt="icon"/>
                                    <div className="arrow_line"/>
                                    <img className="img-fluid" src="images/icons/arrow_down.png" alt="icon"/>
                                </div>
                                <div className="roadmap_txt">
                                    First 250 Walruses Mint Live On The Blockchain
                                </div>
                            </div>
                            {/*     Row */}

                            <div className="roadmap_row">
                                <div className="arrow_col">
                                    <img className="img-fluid arrow_line_icon" src="images/icons/walrus_active.png"
                                         alt="icon"/>
                                    <div className="arrow_line"/>
                                    <img className="img-fluid" src="images/icons/arrow_down.png" alt="icon"/>
                                </div>
                                <div className="roadmap_txt">
                                    Walruses Revealed At Sell Out

                                </div>
                            </div>
                            {/*     Row */}

                            <div className="roadmap_row">
                                <div className="arrow_col">
                                    <img className="img-fluid arrow_line_icon" src="images/icons/walrus_active.png"
                                         alt="icon"/>
                                    <div className="arrow_line"/>
                                    <img className="img-fluid" src="images/icons/arrow_down.png" alt="icon"/>
                                </div>
                                <div className="roadmap_txt">
                                    NYC Beer Pong Tourney Of The Century (Dates TBA)

                                </div>
                            </div>
                            {/*     Row */}

                            <div className="roadmap_row">
                                <div className="arrow_col">
                                    <img className="img-fluid arrow_line_icon" src="images/icons/walrus_active.png"
                                         alt="icon"/>
                                    <div className="arrow_line"/>
                                    <img className="img-fluid" src="images/icons/arrow_down.png" alt="icon"/>
                                </div>
                                <div className="roadmap_txt">
                                    Next Batch Of Walruses Released To Induct New Members

                                </div>
                            </div>
                            {/*     Row */}

                            <div className="roadmap_row">
                                <div className="arrow_col">
                                    <img className="img-fluid arrow_line_icon" src="images/icons/walrus_active.png"
                                         alt="icon"/>
                                    <div className="arrow_line"/>
                                    <img className="img-fluid" src="images/icons/arrow_down.png" alt="icon"/>
                                </div>
                                <div className="roadmap_txt">
                                    Future Events And Utility

                                </div>
                            </div>
                            {/*     Row */}



                            <div className="roadmap_row">
                                <div className="arrow_col">
                                    <img className="img-fluid arrow_line_icon" src="images/icons/walrus_active.png"
                                         alt="icon"/>
                                </div>
                                <div className="roadmap_txt">
                                    Remaining Walruses Released To The Public
                                </div>
                            </div>
                            {/*     Row */}
                        </div>
                    </div>
                    <p className="">
                        <span className="cl_purple"> Note: </span>
                        250 walruses are being withheld from the sale. These will be used for giveaways, puzzle
                        rewards—and for the
                        creators&#x27; memberships.
                    </p>
                </section>

            </section>
            <section  ref={props.sectionRefs[5]}  id="section_team_section" className="container">
                <div id="src_team_section"/>
                <h2 className="info_header">
                    TEAM
                </h2>

                <section id="team_section">
                    {/*             Only on mobile */}

                    <div id="team_mobile_slider" className="d-md-none info_box round_cr">
                        <div className="splide__track">
                            <div className="splide__list">
                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Fixr.png" alt="team member"/>
                                    <h5>Fixr</h5>
                                    <span>
                                    The Don Walrus
                                </span>
                                </div>
                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/IcebergSlim.png" alt="team member"/>
                                    <h5>IcebergSlim</h5>
                                    <span>
                                    Papa Walrus
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Omel.png" alt="team member"/>
                                    <h5>Omel</h5>
                                    <span>
                                    Westside Artisan
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Reza.png" alt="team member"/>
                                    <h5>Reza</h5>
                                    <span>
                                    Head Walrus Coder
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Benbi.png" alt="team member"/>
                                    <h5>Benbi</h5>
                                    <span>
                                    Walrus Experience
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Reforrestation.png" alt="team member"/>
                                    <h5>Reforrestation</h5>
                                    <span>
                                    Street Team
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/EppyTheThird.png" alt="team member"/>
                                    <h5>EppyTheThird</h5>
                                    <span>
                                    Street Team
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Kinara.png" alt="team member"/>
                                    <h5>Kinara</h5>
                                    <span>
                                    Street Team
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Adrian.png" alt="team member"/>
                                    <h5>Adrian</h5>
                                    <span>
                                    Street Team
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/Frostbite.png" alt="team member"/>
                                    <h5>Frostbite</h5>
                                    <span>
                                    Street Team
                                </span>
                                </div>

                                <div className="team_box-mobile splide__slide">
                                    <img src="images/team/team.png" alt="team member"/>
                                    <h5>Ariya</h5>
                                    <span>
                                    Street Team
                                </span>
                                </div>
                            </div>
                        </div>
                        {/*             <div className="wrapper-pagination nav-bar-spide"/> */}
                    </div>

                    {/*             Only on Desktop  */}
                    <div id="team_desktop" className="info_box round_cr d-none d-md-flex">
                        <section className="">
                            <div className="team_box">
                                <img src="images/team/Fixr.png" alt="team member"/>
                                <h5>Fixr</h5>
                                <span>
                                The Don Walrus
                            </span>
                            </div>
                            <div className="team_box">
                                <img src="images/team/IcebergSlim.png" alt="team member"/>
                                <h5>IcebergSlim</h5>
                                <span>
                                Papa Walrus
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Omel.png" alt="team member"/>
                                <h5>Omel</h5>
                                <span>
                                Westside Artisan
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Reza.png" alt="team member"/>
                                <h5>Reza</h5>
                                <span>
                                Head Walrus Coder
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Benbi.png" alt="team member"/>
                                <h5>Benbi</h5>
                                <span>
                                Walrus Experience
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Reforrestation.png" alt="team member"/>
                                <h5>Reforrestation</h5>
                                <span>
                                Street Team
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/EppyTheThird.png" alt="team member"/>
                                <h5>EppyTheThird</h5>
                                <span>
                                Street Team
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Kinara.png" alt="team member"/>
                                <h5>Kinara</h5>
                                <span>
                                Street Team
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Adrian.png" alt="team member"/>
                                <h5>Adrian</h5>
                                <span>
                                Street Team
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/Frostbite.png" alt="team member"/>
                                <h5>Frostbite</h5>
                                <span>
                                Street Team
                            </span>
                            </div>

                            <div className="team_box">
                                <img src="images/team/team.png" alt="team member"/>
                                <h5>Ariya</h5>
                                <span>
                                Street Team
                            </span>
                            </div>

                            <div className="team_box invisible">&nbsp;</div>
                        </section>
                    </div>
                </section>
            </section>


    </>

}
