export function Footer() {
    return <footer>
        <div id="footer_info">
            <img className="img-fluid" src="images/logos/Westside_Walruses_Logo.png" alt="Westside Walruses Logo"/>
            <div id="footer_social" className="d-flex justify-content-between">
                <a className="white-link circle-btn" target="_blank" title="twitter"
                   href="https://twitter.com/RealWalrusNFT"
                   rel="noreferrer">
                    <i className="icon-twitter"/>
                </a>

                <a className="white-link circle-btn" target="_blank" title="discord"
                   href="https://discord.gg/WestsideWalruses" rel="noreferrer">
                    <i className="icon-discord"/>
                </a>
            </div>
            <p>
                (c) 2023 - Iceberg Labs
            </p>
        </div>
    </footer>;
}
